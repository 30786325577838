@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@500&display=swap');

html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
	background-image: url("../textures/map.png");
  background-repeat: no-repeat;
  background-position: absolute;
  background-position: 50% 50%;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Quicksand', sans-serif;
  font-size: 20px;
}

