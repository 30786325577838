.tut-area {
    position: fixed;
    top: 5%;
    left: 0;
    width: 100%;
    height: 90%;
    z-index: -1;
  }
  
  .tut-area.show {
    z-index: 3;
  }
  
  .tut-interface {
    background-color: #DFDFDF;
    display: flex;
    flex-direction: column;
    border-radius: 1rem;
    border: 2px solid black;
    /* border-top-right-radius: 3px; */
    width: 90%;
    height: 100%;
    padding: 10px;
    position: static;
    margin: 0 auto;
    right: auto;
    visibility: hidden;
  }
  
  .tut-interface.show {
    visibility:visible;
  }

  .tut-content {
    margin: 10px;
    height: 100%;
    background-color: white;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .tut-bottom{
    /* position */
    float: left;
    margin-left: 10px;
    margin-bottom: 10px;
    /* appearance
    height: fit-content;
    width: 200px;
    justify-content: flex-end;
    display: flex;
    flex-direction: row;
    align-items: bottom; */
  }

  .tut-top-row{
    /* position */
    margin-left: 10px;
    margin-top: 10px;
    /*appearance*/
    height: fit-content;
    width: 200px;
    justify-content: space-between;
    display: flex;
    flex-direction: row;
    font-size: 30px;
    align-items: bottom;
  }
  
  .close-tut-interface {
    /*apprearance*/
    background-color: #F94144;
    border-radius: 10px;
    width: 40px;
    height: 40px;
    /* content*/
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    color: white;
    /* hide if not shown*/
    visibility: hidden;
  }
  
  .close-tut-interface.show{
    visibility: visible;
  }
  
  .close-tut-interface:hover{
    cursor: pointer;
    border: 2px solid black;
    transition: 50ms;
  }
  
  .close-tut-interface:active{
    cursor: pointer;
    border: 3px solid black;
    transition: 50ms;
  }