.action-menu {
  /* position */
  position:fixed;
  bottom: 5vh;
  right: 5vh;
  /* appearance */
  width: 130px;
  height: fit-content;
  background-color: #dcead1b7;
  visibility: hidden;
  border-radius: 1rem;
  /* how childs are positioned */
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

.action-menu.show {
  visibility: visible;
}

/* properties of all child elements */
.action-menu > * {
  display: block;
  margin-top: 10px;
  width: 110px;
  height: 110px;
  font-size: 20px;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 1rem;
}

/* remove margin for first child */
.action-menu > *:first-child {
  margin-top: 0px;
}

/* add click and hover animations */
.action-menu > *:hover {
  cursor: pointer;
  border: solid 2px black;
  transition: 50ms;
}

.action-menu > *:active {
  cursor: pointer;
  border: solid 3px black;
  transition: 50ms;
}

/* specify backgrounds for the individual icons  */
.remove-button {
  background: rgb(81,204,46);
  background: linear-gradient(0deg, #F7B926  0%, #F9C74F 100%);  
}
  
.open-buy-menu {
  background: rgb(81,204,46);
  background: linear-gradient(0deg, #7DE316  0%, #A8EF61 100%);  
}

.help-button {
  background: rgb(81,204,46);
  background: linear-gradient(0deg, #2E94BF 0%,#82C4E1 100%);  
}

/* style of the abort button */
.abort {
  position:fixed;
  bottom: 5vh;
  right: 5vh;
  /* appearance */
  border: none;
  width: 80px;
  height: 80px;
  background-color: #F94144;
  visibility: hidden;
  border-radius: 1rem;
  /* how childs are positioned */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* how childs are styled */
  font-size: 20px;
}

.abort.show {
  visibility: visible;
}

.abort:hover {
  cursor: pointer;
  border: solid 2px black;
  transition: 50ms;
}

.abort:active {
  cursor: pointer;
  border: solid 3px black;
  transition: 50ms;
}