.chart {
  /*position*/
  position: fixed;
  bottom: 5vh;
  left: 5vh;
  padding: 10px;
  /* appearance */
  width: fit-content;
  height: 310px;
  background-color: #FFFFFFb7;
  padding: 10px;
  outline: 2px solid #7F7F7Fb7;
  outline-offset: -4px;
  border-radius: 10px;
  /* how childs are positioned */
  padding: 10px;
  padding-right: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 0;
  transition: 550ms;
}

.temp-top {
  width: 100%;
}