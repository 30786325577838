.pause-screen {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.26);
  color: white;
  font-size: 30px;
  z-index: 3;
}

.pause-content {
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.continue-button {
  background-color: rgba(156, 156, 156, 0.26);
  margin-top: 20px;
  padding: 10px;
  border-radius: 10px;
  z-index: 3;
}

.continue-button:hover {
  background-color: rgba(156, 156, 156, 0.56);
  cursor: pointer;
  transition: 50ms;
}

.continue-button:active {
  background-color: rgba(87, 87, 87, 0.56);
  cursor: pointer;
  transition: 50ms;
}