.buy-area {
  position: fixed;
  top: 25%;
  left: 0;
  width: 100%;
  height: 460px;
  z-index: 0;
}

.buy-area.show {
  z-index: 1;
}

.buy-menu {
  position: absolute;
  background-color: #dcead1b7;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 1rem;
  /* border-top-right-radius: 3px; */
  width: 700px;
  height: 100%;
  padding: 10px;
  right: -100%; /*hides it */
}

.buy-menu.show {
  position: static;
  margin: 0 auto;
  right: auto;
}

.buy-menu-titles {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  width: 680px;
  height: 40px;
}
.buy-title {
  margin-top: auto;
  margin-bottom: 0;
  margin-left: 10px;
  background-color:#AFAFAF;
  border-radius: 10px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  display:flex;
  justify-content: center;
  position: static;
  width: 115px;
  height: fit-content;
}

.buy-menu-content-wrap {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: flex-start;
  border-radius: 1rem;
  /* border-top-right-radius: 3px; */
  width: 700px;
  height: 400px;
}

.close-buy-menu {
  /* position */
  margin-left: auto; 
  margin-right: 0;
  /*apprearance*/
  background-color: #F94144;
  border-radius: 15px;
  width: 30px;
  height: 30px;
  /* content*/
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 0.5px;
  color: white;
}

.close-buy-menu:hover{
  cursor: pointer;
  border: 2px solid black;
  transition: 50ms;
}

.close-buy-menu:active{
  cursor: pointer;
  border: 3px solid black;
  transition: 50ms;
}

.buy-scrollable {
  background-color:#AFAFAF;
  border-radius: 10px;
  padding: 8px 0px 0px 0px;
  width: 400px;
  height: 100%;
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  overflow-y: scroll; /*  makes scrollable */
}

.gameobject-information-wrapper{
  background-color:#AFAFAF;
  border-radius: 10px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  width: 280px;
  height: 100%;
}

.info-icon-large{
  border: solid 3px black;
  background-color: white;
  margin: 10px auto;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 140px;
  height: 140px;
}

.info-details{
  padding: 10px;
  background-color: white;
  margin: 10px auto;
  border-radius: 10px;
  width: 260px;
  height: 210px;
}

.info-name{
  text-align: center;
  font-weight: bold;
}

.info-text{
  text-align: center;
}

.info-co2, .info-money, .info-energy{
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  align-items:flex-end;
  justify-content: space-between;
  margin-top: 5px;
  margin-right: 20px;
  font-size: 25px;
  /* text-align: center; */
}