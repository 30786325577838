/*Dialog.css*/

.DialogWindow {
    position: absolute;
    background-color: #F9C74F;
    border: solid; /* position fixing -- to-do */ 
    top: 20px;
    left: 1100px;
    right: 20px;
    height: fit-content;
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content:flex-start;
    cursor: pointer;
    z-index: 0;
}

.DialogFooter {
    cursor: pointer;
    font-size: 0.9rem;
    text-align: end;
}
