.buy-tabpanel{
  display:inline-block;
  position: static;
  float: left;
  margin-left: 10px;
  width: 115px;
  height: fit-content;
}

/* not selectable buy card */
.buycard {
  width: 100%;
  margin: 0 auto 10px auto;
  height: 130px; 
  border-radius: 5px;
  border: none;
  position: relative;
  background:#efefef;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  transition: 50ms;
  opacity: 0.4;
}

.buycard.selectable {
  opacity: 1.0;
}

.buycard:hover {
  cursor:unset;
}

.buycard.selectable:hover {
  cursor: pointer;
  border: solid 2px black;
}

.buycard.selectable:active {
  cursor: pointer;
  border: solid 3px black;
}

.buy-tabpanel.carbon .buycard{
 background:#d4f7b0;
 background: linear-gradient(0deg, #93EB3A 0%, #bef389 100%);
}

.buy-tabpanel.power .buycard{
  background:#c0e2f0;
  background: linear-gradient(0deg, #62b6d9 0%, #a1d3e8 100%);
}

.buy-tabpanel.cash .buycard{
  background:#fce2a6;
  background: linear-gradient(0deg, #fbdb90 0%, #fde9bd 100%);
}

.gameobject-type-overlay{
  position: absolute;
  top: 5px;
  left: 5px;
}

.gameobject-icon{
  height: 100px;
  width: 100%;
} 

.gameobject-price{
  /* center text vertically */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  /*properties of the box*/
  height: 30px;
  width: 100%;
  background-color: #ffffff;
  /*position at the bottom of the buycard*/
  position: absolute;
  bottom: 0;
  left: 0;
}

.gameobject-price > * {
  margin-right: 10px;
}