

.wrapper {
  position: absolute;
  width: 600px;
  height: 600px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 0;
}

.floor {
  transform: rotateX(55deg) rotateZ(45deg);
}

.floor .tile {
  display: inline-block;
  background: rgba(0, 0, 0, 0.1);
  width: 75px;
  height: 75px;
  margin: 0;
  padding: 0;
  border: solid 1px rgba(0, 0, 0, 0.1);
  text-align: center;
  position: relative;
  vertical-align: top;
}


.floor .tile img {
  pointer-events: none;
  z-index: 0;
  transform: rotateZ(-45deg) rotateX(-55deg) scale(1.4, 3.5) translateY(-13px);
}

.floor .tile:hover {
  background: rgba(0, 0, 0, 0.5);
}

.floor .tile.bulldozing.occupied:hover {
  cursor: pointer;
  background: rgba(0, 255, 128, 0.5);
}

.floor .tile.bulldozing:hover {
  cursor: pointer;
  background: rgba(255, 3, 3, 0.5);
}

.floor .tile.selecting:hover {
  cursor: pointer;
  background: rgba(0, 255, 128, 0.5);
}

.floor .tile.selecting.occupied:hover {
  cursor: unset;
  background: rgba(255, 3, 3, 0.5);
}


/*Stat Display*/

.just-a-taste{
  height: 100%;
  width: 100%;
  display: block;
  position: absolute;
  /* transform: rotateZ(-45deg) rotateX(-55deg); */
  top: -200px;
  left: -100px;
}

.info{
  position: absolute;
  z-index: 1;
}

.just-a-taste .info-energy{
  transform: translate(60px,60px);
}

.just-a-taste .info-co2{
 transform: translate(120px,120px);
}

.just-a-taste .info p{
  z-index: 1;
  position: absolute;
  transform: rotateZ(-45deg) rotateX(-55deg) scale(1.4, 3.5) translateX(80px) translateY(-25px);
  white-space: nowrap;
}