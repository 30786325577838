
/* React Bootstrap additional styling */
.progress-bar{
    height: inherit;
    opacity: inherit;
    color: white !important;
    padding-left: 6mm !important;
    text-align: left;
}

.bg-warning{
    background-color: #f9c74f !important;
}

.bg-success {
    background-color: #a1d3e8 !important;
}

.bg-danger {
    background-color: #7646A8 !important;
}

/* our styling */

.Bars{
    position: fixed;
    top: 10vh;
    left: 5vh;
    width: 500px;
}

.bar-container{
    position: relative;
    display: grid;
    color: white;
    width: 100%;
    height: 10mm;
    margin-bottom: 5mm;
    border-radius: 50px;
    border: solid 2px black;
}

.Bar {
    border-radius: inherit;
    width: 100%;
    height: 100%;
    top: 0;
    background: none;
}

.bar-container p {
    left: 40%;
    vertical-align: middle;
    position: absolute;
    white-space: nowrap;
    z-index: 1;
}

.Icon{
    display: flex;
    justify-content: center;
    align-items: center;
    left: -10px;
    top: -10px;
    position: absolute;
    height: 15mm;
    width: 15mm;
    border-radius: 100%;
    border: solid 0.5mm black;
}


.MoneyIcon{
    background-color: #fcbc0a;
}

.EnergyIcon{
    background-color: #a1d3e8;
}

.TempIcon{
    background-color: #7646A8;
}