@import url(https://fonts.googleapis.com/css2?family=Quicksand:wght@500&display=swap);
html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
	background-image: url(/static/media/map.c2014cd3.png);
  background-repeat: no-repeat;
  background-position: absolute;
  background-position: 50% 50%;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Quicksand', sans-serif;
  font-size: 20px;
}




.wrapper {
  position: absolute;
  width: 600px;
  height: 600px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 0;
}

.floor {
  transform: rotateX(55deg) rotateZ(45deg);
}

.floor .tile {
  display: inline-block;
  background: rgba(0, 0, 0, 0.1);
  width: 75px;
  height: 75px;
  margin: 0;
  padding: 0;
  border: solid 1px rgba(0, 0, 0, 0.1);
  text-align: center;
  position: relative;
  vertical-align: top;
}


.floor .tile img {
  pointer-events: none;
  z-index: 0;
  transform: rotateZ(-45deg) rotateX(-55deg) scale(1.4, 3.5) translateY(-13px);
}

.floor .tile:hover {
  background: rgba(0, 0, 0, 0.5);
}

.floor .tile.bulldozing.occupied:hover {
  cursor: pointer;
  background: rgba(0, 255, 128, 0.5);
}

.floor .tile.bulldozing:hover {
  cursor: pointer;
  background: rgba(255, 3, 3, 0.5);
}

.floor .tile.selecting:hover {
  cursor: pointer;
  background: rgba(0, 255, 128, 0.5);
}

.floor .tile.selecting.occupied:hover {
  cursor: unset;
  background: rgba(255, 3, 3, 0.5);
}


/*Stat Display*/

.just-a-taste{
  height: 100%;
  width: 100%;
  display: block;
  position: absolute;
  /* transform: rotateZ(-45deg) rotateX(-55deg); */
  top: -200px;
  left: -100px;
}

.info{
  position: absolute;
  z-index: 1;
}

.just-a-taste .info-energy{
  transform: translate(60px,60px);
}

.just-a-taste .info-co2{
 transform: translate(120px,120px);
}

.just-a-taste .info p{
  z-index: 1;
  position: absolute;
  transform: rotateZ(-45deg) rotateX(-55deg) scale(1.4, 3.5) translateX(80px) translateY(-25px);
  white-space: nowrap;
}
.buy-tabpanel{
  display:inline-block;
  position: static;
  float: left;
  margin-left: 10px;
  width: 115px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

/* not selectable buy card */
.buycard {
  width: 100%;
  margin: 0 auto 10px auto;
  height: 130px; 
  border-radius: 5px;
  border: none;
  position: relative;
  background:#efefef;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  transition: 50ms;
  opacity: 0.4;
}

.buycard.selectable {
  opacity: 1.0;
}

.buycard:hover {
  cursor:unset;
}

.buycard.selectable:hover {
  cursor: pointer;
  border: solid 2px black;
}

.buycard.selectable:active {
  cursor: pointer;
  border: solid 3px black;
}

.buy-tabpanel.carbon .buycard{
 background:#d4f7b0;
 background: linear-gradient(0deg, #93EB3A 0%, #bef389 100%);
}

.buy-tabpanel.power .buycard{
  background:#c0e2f0;
  background: linear-gradient(0deg, #62b6d9 0%, #a1d3e8 100%);
}

.buy-tabpanel.cash .buycard{
  background:#fce2a6;
  background: linear-gradient(0deg, #fbdb90 0%, #fde9bd 100%);
}

.gameobject-type-overlay{
  position: absolute;
  top: 5px;
  left: 5px;
}

.gameobject-icon{
  height: 100px;
  width: 100%;
} 

.gameobject-price{
  /* center text vertically */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  /*properties of the box*/
  height: 30px;
  width: 100%;
  background-color: #ffffff;
  /*position at the bottom of the buycard*/
  position: absolute;
  bottom: 0;
  left: 0;
}

.gameobject-price > * {
  margin-right: 10px;
}
.buy-area {
  position: fixed;
  top: 25%;
  left: 0;
  width: 100%;
  height: 460px;
  z-index: 0;
}

.buy-area.show {
  z-index: 1;
}

.buy-menu {
  position: absolute;
  background-color: #dcead1b7;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 1rem;
  /* border-top-right-radius: 3px; */
  width: 700px;
  height: 100%;
  padding: 10px;
  right: -100%; /*hides it */
}

.buy-menu.show {
  position: static;
  margin: 0 auto;
  right: auto;
}

.buy-menu-titles {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  width: 680px;
  height: 40px;
}
.buy-title {
  margin-top: auto;
  margin-bottom: 0;
  margin-left: 10px;
  background-color:#AFAFAF;
  border-radius: 10px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  display:flex;
  justify-content: center;
  position: static;
  width: 115px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.buy-menu-content-wrap {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: flex-start;
  border-radius: 1rem;
  /* border-top-right-radius: 3px; */
  width: 700px;
  height: 400px;
}

.close-buy-menu {
  /* position */
  margin-left: auto; 
  margin-right: 0;
  /*apprearance*/
  background-color: #F94144;
  border-radius: 15px;
  width: 30px;
  height: 30px;
  /* content*/
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 0.5px;
  color: white;
}

.close-buy-menu:hover{
  cursor: pointer;
  border: 2px solid black;
  transition: 50ms;
}

.close-buy-menu:active{
  cursor: pointer;
  border: 3px solid black;
  transition: 50ms;
}

.buy-scrollable {
  background-color:#AFAFAF;
  border-radius: 10px;
  padding: 8px 0px 0px 0px;
  width: 400px;
  height: 100%;
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  overflow-y: scroll; /*  makes scrollable */
}

.gameobject-information-wrapper{
  background-color:#AFAFAF;
  border-radius: 10px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  width: 280px;
  height: 100%;
}

.info-icon-large{
  border: solid 3px black;
  background-color: white;
  margin: 10px auto;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 140px;
  height: 140px;
}

.info-details{
  padding: 10px;
  background-color: white;
  margin: 10px auto;
  border-radius: 10px;
  width: 260px;
  height: 210px;
}

.info-name{
  text-align: center;
  font-weight: bold;
}

.info-text{
  text-align: center;
}

.info-co2, .info-money, .info-energy{
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  align-items:flex-end;
  justify-content: space-between;
  margin-top: 5px;
  margin-right: 20px;
  font-size: 25px;
  /* text-align: center; */
}
.action-menu {
  /* position */
  position:fixed;
  bottom: 5vh;
  right: 5vh;
  /* appearance */
  width: 130px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background-color: #dcead1b7;
  visibility: hidden;
  border-radius: 1rem;
  /* how childs are positioned */
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

.action-menu.show {
  visibility: visible;
}

/* properties of all child elements */
.action-menu > * {
  display: block;
  margin-top: 10px;
  width: 110px;
  height: 110px;
  font-size: 20px;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 1rem;
}

/* remove margin for first child */
.action-menu > *:first-child {
  margin-top: 0px;
}

/* add click and hover animations */
.action-menu > *:hover {
  cursor: pointer;
  border: solid 2px black;
  transition: 50ms;
}

.action-menu > *:active {
  cursor: pointer;
  border: solid 3px black;
  transition: 50ms;
}

/* specify backgrounds for the individual icons  */
.remove-button {
  background: rgb(81,204,46);
  background: linear-gradient(0deg, #F7B926  0%, #F9C74F 100%);  
}
  
.open-buy-menu {
  background: rgb(81,204,46);
  background: linear-gradient(0deg, #7DE316  0%, #A8EF61 100%);  
}

.help-button {
  background: rgb(81,204,46);
  background: linear-gradient(0deg, #2E94BF 0%,#82C4E1 100%);  
}

/* style of the abort button */
.abort {
  position:fixed;
  bottom: 5vh;
  right: 5vh;
  /* appearance */
  border: none;
  width: 80px;
  height: 80px;
  background-color: #F94144;
  visibility: hidden;
  border-radius: 1rem;
  /* how childs are positioned */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* how childs are styled */
  font-size: 20px;
}

.abort.show {
  visibility: visible;
}

.abort:hover {
  cursor: pointer;
  border: solid 2px black;
  transition: 50ms;
}

.abort:active {
  cursor: pointer;
  border: solid 3px black;
  transition: 50ms;
}

/* React Bootstrap additional styling */
.progress-bar{
    height: inherit;
    opacity: inherit;
    color: white !important;
    padding-left: 6mm !important;
    text-align: left;
}

.bg-warning{
    background-color: #f9c74f !important;
}

.bg-success {
    background-color: #a1d3e8 !important;
}

.bg-danger {
    background-color: #7646A8 !important;
}

/* our styling */

.Bars{
    position: fixed;
    top: 10vh;
    left: 5vh;
    width: 500px;
}

.bar-container{
    position: relative;
    display: grid;
    color: white;
    width: 100%;
    height: 10mm;
    margin-bottom: 5mm;
    border-radius: 50px;
    border: solid 2px black;
}

.Bar {
    border-radius: inherit;
    width: 100%;
    height: 100%;
    top: 0;
    background: none;
}

.bar-container p {
    left: 40%;
    vertical-align: middle;
    position: absolute;
    white-space: nowrap;
    z-index: 1;
}

.Icon{
    display: flex;
    justify-content: center;
    align-items: center;
    left: -10px;
    top: -10px;
    position: absolute;
    height: 15mm;
    width: 15mm;
    border-radius: 100%;
    border: solid 0.5mm black;
}


.MoneyIcon{
    background-color: #fcbc0a;
}

.EnergyIcon{
    background-color: #a1d3e8;
}

.TempIcon{
    background-color: #7646A8;
}
.tut-area {
    position: fixed;
    top: 5%;
    left: 0;
    width: 100%;
    height: 90%;
    z-index: -1;
  }
  
  .tut-area.show {
    z-index: 3;
  }
  
  .tut-interface {
    background-color: #DFDFDF;
    display: flex;
    flex-direction: column;
    border-radius: 1rem;
    border: 2px solid black;
    /* border-top-right-radius: 3px; */
    width: 90%;
    height: 100%;
    padding: 10px;
    position: static;
    margin: 0 auto;
    right: auto;
    visibility: hidden;
  }
  
  .tut-interface.show {
    visibility:visible;
  }

  .tut-content {
    margin: 10px;
    height: 100%;
    background-color: white;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .tut-bottom{
    /* position */
    float: left;
    margin-left: 10px;
    margin-bottom: 10px;
    /* appearance
    height: fit-content;
    width: 200px;
    justify-content: flex-end;
    display: flex;
    flex-direction: row;
    align-items: bottom; */
  }

  .tut-top-row{
    /* position */
    margin-left: 10px;
    margin-top: 10px;
    /*appearance*/
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: 200px;
    justify-content: space-between;
    display: flex;
    flex-direction: row;
    font-size: 30px;
    align-items: bottom;
  }
  
  .close-tut-interface {
    /*apprearance*/
    background-color: #F94144;
    border-radius: 10px;
    width: 40px;
    height: 40px;
    /* content*/
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    color: white;
    /* hide if not shown*/
    visibility: hidden;
  }
  
  .close-tut-interface.show{
    visibility: visible;
  }
  
  .close-tut-interface:hover{
    cursor: pointer;
    border: 2px solid black;
    transition: 50ms;
  }
  
  .close-tut-interface:active{
    cursor: pointer;
    border: 3px solid black;
    transition: 50ms;
  }
.pause-screen {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.26);
  color: white;
  font-size: 30px;
  z-index: 3;
}

.pause-content {
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.continue-button {
  background-color: rgba(156, 156, 156, 0.26);
  margin-top: 20px;
  padding: 10px;
  border-radius: 10px;
  z-index: 3;
}

.continue-button:hover {
  background-color: rgba(156, 156, 156, 0.56);
  cursor: pointer;
  transition: 50ms;
}

.continue-button:active {
  background-color: rgba(87, 87, 87, 0.56);
  cursor: pointer;
  transition: 50ms;
}
/*Dialog.css*/

.DialogWindow {
    position: absolute;
    background-color: #F9C74F;
    border: solid; /* position fixing -- to-do */ 
    top: 20px;
    left: 1100px;
    right: 20px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content:flex-start;
    cursor: pointer;
    z-index: 0;
}

.DialogFooter {
    cursor: pointer;
    font-size: 0.9rem;
    text-align: end;
}

.chart {
  /*position*/
  position: fixed;
  bottom: 5vh;
  left: 5vh;
  padding: 10px;
  /* appearance */
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: 310px;
  background-color: #FFFFFFb7;
  padding: 10px;
  outline: 2px solid #7F7F7Fb7;
  outline-offset: -4px;
  border-radius: 10px;
  /* how childs are positioned */
  padding: 10px;
  padding-right: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 0;
  transition: 550ms;
}

.temp-top {
  width: 100%;
}
